@import 'assets/styles/Colors.scss';

.PageTitle {
    color: $darkest-green!important;
    user-select: none;
    font-size: 1.5rem!important;

    .Header {
        .Icon {
            font-size: 2rem;

            &:not(.BigIcon) {
                margin-right: 0.5rem!important;
            }

            &.BigIcon {
                margin-right: 1rem!important;
            }
        }

        & > span {
            margin: 0 0 0 0.2rem;
            padding: 0 0.2em;
            border-radius: 0.2rem;
            border: 0.071rem solid $light-green;
            background: transparentize($light-green, 0.7);
            color: $dark-green;
        }
    }

    .SubHeader {
        margin-top: 0.5rem!important;
    }
}