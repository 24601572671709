@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.MessageOptions {
    position: absolute;
    right: 0.3rem;
    width: 100%;
    min-width: 15rem;
    border-radius: 0.357rem;
    border: 0.071rem solid $light-grey;
    background: white;
    filter: drop-shadow(0 0 0.3rem $dark-shadow-grey);
    user-select: none;
    z-index: 1;

    @include media('<=mobile') {
        min-width: 15rem;
    }

    &.Upward {
        bottom: 100%;
    }

    &:not(.Upward) {
        top: 1.429rem;
    }

    .Option {
        display: flex;
        padding: 0.5rem 1rem;
        border-radius: inherit;
        color: $dark-grey;

        &:not(:last-child) {
            border-bottom: 0.071rem solid transparentize($light-grey, 0.5);
        }

        &:hover {
            background: $lightest-grey;
            font-weight: bold;
            cursor: pointer;
        }

        .Icon {
            margin-right: 0.7rem;
            font-size: 1.2rem;
            color: $dark-grey;
        }

        span {
            font-size: 1.023rem;
        }
    }
}