@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.UserWidget {
    position: relative;
    border-bottom-left-radius: 0.3rem;
    border-color: transparentize($light-grey, 0.3);
    border-style: solid;
    border-width: 0 0 0.071rem 0.071rem;
    user-select: none;
    background: white;

    &.Selected,
    &:hover {
        border-color: $light-grey;
        background: $lighter-grey;
        cursor: pointer;
    }

    .UserInfo {
        display: flex;
        align-items: center;
        padding: 0.6rem 1.2rem;

        span {
            font-size: 1.1rem;

            &.FirstName {
                @include media('>mobile') {
                    &:after {
                        content: ' ';
                        white-space: pre;
                    }
                }
            }

            &.LastName {
                @include media('<=mobile') {
                    display: none;
                }
            }
        }

        img {
            width: 2.3rem;
            height: 2.3rem;
            margin-left: 0.7rem;
        }
    }

    .Menu {
        @extend %HeaderWidgetMenu;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;

        .Option {
            display: flex;
            padding: 0.8rem 1rem;
            border-radius: inherit;

            &:not(:last-child) {
                border-bottom: 0.071rem solid transparentize($light-grey, 0.5);
            }

            &:hover {
                background: $lightest-grey;
                font-weight: bold;
            }

            .Icon {
                margin-right: 0.7rem;
                font-size: 1.2rem;
                color: $dark-grey;
            }

            span {
                font-size: 1.023rem;
            }
        }
    }
}