@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.SideBar {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 16.429rem;
    max-width: 16.429rem;
    background: $darkest-green;
    box-shadow: 0 0 0.5rem $dark-shadow-grey;
    user-select: none;
    overflow-y: auto;
    padding-bottom: 2rem;

    @include media('<=mobile') {
        position: absolute;
        top: 0;
        bottom: 0;
        z-index: 2;
    }

    .MainContentWrapper {
        & > header {
            display: flex;
            align-items: center;
            padding: 2.5rem 1.3rem;
            cursor: default;

            & > .Icon {
                position: absolute;
                top: 1rem;
                right: 1rem;

                &:hover {
                    cursor: pointer;
                    color: $lightest-grey;
                }

                @include media('>mobile') {
                    display: none;
                }
            }

            & > img {
                width: 100%;
            }
        }
    }

    .Calendar {
        margin-top: 2rem;
        padding: 0 0.5rem;
    }
}