@import 'assets/styles/Colors.scss';

.Content {
    padding-top: 2rem;

    .RequestsTable {
        font-size: 1.2rem!important;

        .RequestsColumn {
            color: $darkest-green;
        }

        .RequestsRow {
            color: $darker-blue;
        }

        .RequestsCell {
            padding-top: 2rem;
            padding-bottom: 2rem;

            .ActionButtons {
                display: flex;
                flex-wrap: wrap;

                .ActionButton {
                    font-size: 1.1rem;
                    margin: 0.1rem 0.3rem 0.1rem 0;
                }
            }
        }
    }

    .RequestsPagination {
        margin-top: 3rem!important;
    }

    .NoRequestsMessage {
        font-size: 2rem;
        text-align: center;
        user-select: none;

        .MessageHeader {
            margin-top: 0;

            .MessageTitle {
                color: $darkest-green;
            }

            .MessageSubtitle {
                font-size: 2rem!important;
            }
        }
    }
}

.ApproveRequestForm {
    margin-top: 1.5rem;
}