@import 'assets/styles/Colors.scss';

.Content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 5vh;
    background-image: linear-gradient(to bottom right, transparentize(black, 0.1), $light-green, transparentize(black, 0.1));

    img {
        width: 20vw;
        min-width: 14.286rem;
        -webkit-filter: drop-shadow(0 0 0.214rem transparentize(black, 0.7));
        filter: drop-shadow(0 0 0.214rem transparentize(black, 0.7));
    }

    & > div {
        text-align: center;
    }

    h1 {
        margin: 0;
        font-size: 5rem;
        color: $dark-green;
    }

    h2 {
        margin-top: 1rem;
        margin-bottom: 2rem;
        font-size: 2.5rem;
        color: $dark-green;
    }

    .GoHomeButton {
        margin-top: 1rem;
    }
}