@import 'assets/styles/Colors.scss';

.LinkCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 17.857rem;
    border: 0.071rem solid $light-grey;
    border-radius: 2rem;
    padding: 4rem 1rem;
    margin: 0.5rem 0;
    flex: 1 0 0;
    font-size: calc(1.4rem + 0.5vw);
    font-weight: bold;
    line-height: 1.1;
    text-align: center;

    color: $dark-green;
    user-select: none;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 0.357rem $lighter-shadow-grey;
        color: $bright-green;
    }
}