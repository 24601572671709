@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.UserActionsPanel {
    @extend %StandardPanel;

    .PanelHeader {
        font-size: 1.3rem!important;
        color: $darkest-green!important;
    }

    .Actions {
        margin-top: 1.5rem;

        .ResetPasswordButton {
            margin-top: 0.5rem;
        }
    }
}