@import 'assets/styles/Colors.scss';

.RoundIconButton {
    position: relative;
    display: flex;
    user-select: none;
    padding: 0.7rem 0.7rem;
    cursor: pointer;
    border: 0.071rem solid transparent;
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 0.214rem $mid-dark-grey;

    &:hover {
        background: $lightest-grey;
        box-shadow: 0 0 0.214rem transparentize($mid-dark-grey, 0.3);
    }

    &.Small {
        padding: 0.4rem;

        .Icon {
            font-size: 0.9rem;
        }
    }

    .Icon {
        font-size: 1.12rem;
        color: $darkest-green;
        margin: 0 0 0.2rem 0;
    }

    .Label {
        top: -0.3rem!important;
    }
}