@import 'assets/styles/Colors.scss';

.Content {
    padding-top: 1.5rem;

    .ManagementPanel {
        .ManagementPanelMenu {
            background: $darkest-green!important;
            overflow-x: auto;
            margin: 0;

            .MenuItem {
                background: transparentize(white, 0.9)!important;

                &:before {
                    background: transparentize(white, 0.6)!important;
                    width: 0.143rem!important;
                }

                &:hover {
                    background: transparentize(white, 0.8)!important;
                }

                &[class*='active'] {
                    background: transparentize($bright-green, 0.5)!important;
                    color: white!important;
                    font-weight: bold!important;
                }
            }
        }

        .RecordPane {
            padding: 0;
            margin: 0;

            &.RecordCommunicationsPane {
                background-color: $pantone-432;
                border: solid black;
                border-width: 0 0.071rem 0.071rem 0.071rem;
            }
        }
    }
}