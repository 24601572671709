@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Content {
    padding-top: 1.5rem;

    .MainPanel {
        @extend %StandardPanel;

        .DateFields {
            margin-bottom: 0;

            .DateField {
                &:last-child {
                    margin-bottom: 0!important;
                }
            }
        }

        .Actions {
            padding-top: 1rem;

            .ButtonsWrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                @include media('<=mobile') {
                    flex-direction: column-reverse;

                    .ActionButton {
                        width: 100%;

                        &:first-child {
                            margin-top: 0.3rem;
                        }
                    }
                }
            }
        }
    }
}