@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.MessageItem {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.2rem;
    min-width: 55%;
    border-radius: 0.2rem;
    -webkit-transition: background 0.1s linear;
    -ms-transition: background 0.1s linear;
    transition: background 0.1s linear;

    @include media('<=mobile') {
        min-width: 15rem;
    }

    &:hover {
        .MessageOptionsIconContainer {
            background: radial-gradient(
                at top right,
                transparentize(black, 0.7) 60%,
                transparent 80%
            );
            .MessageOptionsIcon {
                opacity: 1!important;
            }
        }
    }

    &.Sent {
        margin-left: 5rem;
        max-width: 75%;
        align-self: flex-end;
        color: white;

        @include media('<=tablet') {
            max-width: unset;
        }

        @include media('<=mobile') {
            margin-left: 3rem;
        }

        &:not(.Confidential) {
            background: transparentize($bright-green, 0.55);
        }

        &.Confidential {
            background: transparentize($error-red, 0.3);
        }
    }

    &.Received {
        margin-right: 5rem;
        max-width: 75%;
        align-self: flex-start;

        @include media('<=tablet') {
            max-width: unset;
        }

        @include media('<=mobile') {
            margin-right: 3rem;
        }

        &:not(.Confidential) {
            background: $light-green;
        }

        &.Confidential {
            background: $light-red;
        }
    }

    &.Annotation,
    &.Upload {
        color: white;
        border-radius: 1rem;
        margin: 1.5rem auto 1rem;
        min-width: unset;
        width: auto;
        max-width: 80%;
        border: 1px solid grey;
        background: transparentize(white, 0.9);

        @include media('<=mobile') {
            max-width: 90%;
        }

        &.Confidential {
            background: transparentize(black, 0.6);
            border-color: $error-red;
        }

        .MessageContent {
            .MessageText {
                display: flex;
                align-items: center;
            }
        }
    }

    &.TopSeparated {
        margin-top: 1rem;
    }

    .MessageContent {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 0.5rem 2rem 0.5rem 1rem;

        @include media('<=mobile') {
            padding-right: 1rem;
        }

        .MessageSender {
            font-weight: bold;
            margin-bottom: 0.3rem;

            &.Clickable {
                &:hover {
                    cursor: pointer;
                    color: $darkest-blue;
                }
            }
        }

        .MessageText {
            word-break: break-word;
            white-space: pre-wrap;

            .MessageIcon {
                margin: 0 1rem 0 0.3rem;

                @include media('<=mobile') {
                    margin-left: 0;
                }
            }

            .UploadedFilesList {
                margin-top: 0.5rem;

                .UploadedFile {
                    color: lightblue;

                    &:before {
                        color: white!important;
                    }

                    &:hover {
                        color: $light-blue;
                    }
                }
            }
        }

    }

    .MessageMeta {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        border-radius: inherit;

        .MessageOptionsIconContainer {
            position: absolute;
            left: 0;
            right: 0;
            text-align: right;
            padding-right: 0.3rem;
            border-top-right-radius: inherit;
            -webkit-transition: background 0.1s linear;
            -ms-transition: background 0.1s linear;
            transition: background 0.1s linear;

            .MessageOptionsIcon {
                vertical-align: top;
                color: white;
                margin: 0;
                opacity: 0;
            }
        }

        .MessageTime {
            margin-top: auto;
            padding: 0 0.5rem 0.1rem 0;
            font-style: italic;
            font-size: smaller;
        }
    }
}