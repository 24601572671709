@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.CompactTable {
    background: transparent!important;
    margin: 0!important;
    border: none!important;
    border-radius: inherit!important;
    font-size: 1.1rem!important;

    thead,
    tbody {
        background: transparent!important;

        th,
        td {
            border: none!important;
        } 
    }

    thead {
        tr {
            th {
                background: transparent!important;
                text-transform: uppercase!important;
                color: $dark-blue!important;

                @include media('>mobile') {
                    border-bottom: 0.071rem solid transparentize($light-grey, 0.3)!important;
                }
            }
        }
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background: white!important;

                td {
                    background: white!important;
                }
            }

            &:not(:last-child) {
                td {
                    @include media('>mobile') {
                        border-bottom: 0.071rem solid transparentize($light-grey, 0.3)!important;
                    }
                }
            }

            &:hover:not(.NoResultsRow) {
                background: $bright-green!important;

                td {
                    background: $bright-green!important;
                    color: white!important;
                }
            }

            &.SelectableRow {
                cursor: pointer;   
            }

            &.NoResultsRow {
                text-align: center;
            }

            td {
                padding-top: 1.1rem!important;
                padding-bottom: 1.1rem!important;
                color: $darkest-blue;
            }
        }
    }
}