@import 'assets/styles/Colors.scss';

.RoundedPagination {
    display: flex!important;
    justify-content: center;
    border: none!important;
    box-shadow: none!important;
    flex-wrap: wrap!important;

    .PageItem,
    .EllipsisItem,
    .PreviousItem,
    .NextItem {
        border-radius: 50%!important;
        margin: 0 0.4rem 0.4rem 0.4rem!important;
        font-weight: bold!important;
        font-size: 1.1rem;
        box-shadow: 0 0.214rem 0.286rem $mid-dark-grey!important;
        width: 3.071rem!important;
        height: 3.071rem!important;
        min-width: 3.071rem!important;
        min-height: 3.071rem!important;
        padding: 0!important;
        align-items: center;
        justify-content: center;

        &:before {
            content: none!important;
        }

        .PaginationIcon {
            margin: 0!important;
        }
    }

    .PageItem {
        &[class*='active'] {
            cursor: default!important;
            color: $light-blue!important;
            background: $dark-green!important;
        }
    }

    .PageItem:not([class*='active']),
    .PreviousItem,
    .NextItem {
        color: $darker-blue!important;
        background: transparentize($bright-green, 0.5)!important;

        &:hover {
            background: $light-green!important;
        }
    }

    .EllipsisItem {
        cursor: default!important;
        background: $light-green!important;
    }
}