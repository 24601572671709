@import 'assets/styles/Colors.scss';

@mixin button-properties($args...) {
    $class: nth($args, 1);
    $color: nth($args, 2);

    &.#{$class} {
        box-shadow: 0 0 0 0.143rem $color inset!important;
        color: $color!important;

        &:hover {
            background: $color!important;
            color: white!important;
        }
    }
}

.BasicButton {
    background: transparentize(white, 0.4)!important;

    @include button-properties('Red', $negative-red);
    @include button-properties('Gray', $pantone-432);
    @include button-properties('DarkGreen', $darkest-green);
}