@import 'include-media/dist/_include-media';
@import './Colors.scss';
@import './Constants.scss';

.Dialog[class*='modal'] {
    .DialogIcon {
        margin-right: 0.5rem!important;

        &.InfoIcon {
            color: $dark-green!important;
        }
    }

    & > .Actions {
        padding-bottom: 1rem!important;

        &.ExtraActions {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .ActionButton {
            margin-left: 0.25rem!important;
        }
    }
}

.ExcelGreen {
    color: $excel-green!important;
}