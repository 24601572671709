@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Content {
    padding-top: 2rem;

    .EventsWrapper {
        padding-top: 1rem;

        .MessageIcon {
            @include media('<=mobile') {
                display: none!important;
            }
        }

        .Event {
            margin: 0 0 1.5rem!important;
            padding: 1.5rem 1.5rem 1rem;
            box-shadow: rgba(0, 0, 0, 0.25) 0 0.0625rem 0.0625rem,
                        rgba(0, 0, 0, 0.25) 0 0.125rem 0.5rem,
                        rgba(255, 255, 255, 0.1) 0 0 0 0.071rem inset;
            border-radius: 0.5rem;
            transition: background 0.2s!important;
            transition: box-shadow 0.2s;

            &:hover {
                box-shadow: rgba(0, 0, 0, 0.4) 0 0.143rem 0.286rem,
                            rgba(0, 0, 0, 0.3) 0 0.5rem 0.929rem -0.214rem,
                            rgba(0, 0, 0, 0.2) 0 -0.214rem 0 inset;
            }

            &:not(.Expired) {
                background: transparentize($light-green, 0.4);

                &:hover {
                    background: transparentize($dark-green, 0.85);
                }
            }

            &.Expired {
                background: $lighter-grey;

                &:hover {
                    background: transparentize($light-grey, 0.4);
                }
            }

            &.Expired {
                .EventHeader {
                    color: $pantone-432!important;
                }

                .InvitationInfoLabel {
                    background: transparentize($pantone-432, 0.2);
                    border: 0.071rem solid $pantone-432;
                }
            }

            &:not(.Expired) {
                .EventHeader {
                    color: $darkest-green!important;
                }

                .InvitationInfoLabel {
                    background: transparentize($dark-green, 0.2);
                    border: 0.071rem solid $darkest-green;
                }
            }

            .EventHeader {
                display: flex!important;
                align-items: center;
                margin-bottom: 0.3rem!important;

                .HeaderSeparator {
                    &:after {
                        content: '•';
                        font-size: 0.7rem;
                        font-weight: normal;
                        margin: 0 0.6rem 0 0.6rem;
                    }
                }

                .EventTitle {
                    font-weight: normal;
                }
            }

            .EventDescription {
                margin: 0.5rem 0 1rem;
                padding: 0.3rem 1rem 0.3rem 0;
                max-height: 15rem;
                overflow-y: auto;
                font-style: italic;
                white-space: pre-wrap;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            .InvitationInfoLabel {
                color: white;
                
                span {
                    margin-left: 0.4rem;
                    font-weight: normal;
                }
            }
        }
    }
}