@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.ProfilePicturePanel {
    @extend %StandardPanel;
    display: flex;
    flex-direction: column;

    .PanelHeader {
        font-size: 1.3rem!important;
        color: $darkest-green!important;
    }

    .EditingArea {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex: 1 1 auto;
        padding: 1rem 0;

        .ProfilePicture {
            margin-bottom: 0.5rem;
        }

        .RestorePictureOption {
            border: 0.071rem solid $negative-red;
            border-radius: 0.3rem;
            padding: 0.1rem 0.3rem;
            margin: 0.2rem 0;
            color: $negative-red;

            &:hover {
                cursor: pointer;
                color: $dark-grey;
                border-color: $dark-grey;
            }
        }

        .ChangePictureOption {
            font-weight: bold;
            color: $darkest-green;
            border: 0.071rem solid $darkest-green;
            border-radius: 0.3rem;
            padding: 0.1rem 0.3rem;
            margin: 0.2rem 0 0;

            &:hover {
                cursor: pointer;
                color: $dark-green;
                border-color: $dark-green;
            }
        }

        input[type='file'] {
            display: none;
        }
    }
}

.CroppableImage {
    text-align: center;

    img {
        max-height: 20rem!important;
    }
}