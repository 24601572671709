@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.SelectedFiles {
    @extend %StandardPanel;

    padding: 1rem 0 0 0;
    background: transparentize($lightest-grey, 0.5);

    .Header {
        margin: 0 1rem;

        .FilesAmount {
            margin-left: 0.5rem;
        }
    }

    .FilesList {
        display: flex;
        padding: 1rem;
        flex-wrap: wrap;
        max-height: 20rem;
        overflow-y: auto;
    }
}

.FileItem {
    flex: 1 1 0;
    min-width: 18rem;
    max-width: 24rem;
    padding: 0.5rem;
    border: 0.071rem solid $light-grey;
    border-radius: 0.2rem;
    margin: 0.2rem 0.5rem;
    background: white;
    white-space: nowrap;
    overflow: hidden;
    user-select: none;

    @include media('<=mobile') {
        margin: 0.2rem 0;
    }

    & > div {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & > div {
            display: flex;
            align-items: center;
            overflow: hidden;
            width: 100%;

            .FileIcon {
                margin-right: 0.5rem;
            }

            .FileName {
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .DeleteFileButton {
            display: flex;
            align-items: center;
            margin: 0 0 0 1rem;
        }
    }
}