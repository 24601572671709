@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.SearchPanel {
    @extend %StandardPanel;

    padding: 0;

    .SearchBar {
        display: flex;
        padding: 1.3rem 3.75rem;
        border-bottom: 0.071rem solid transparentize($dark-grey, 0.75);

        @include media('<=mobile') {
            padding-left: 1.3rem;
            padding-right: 1.3rem;
        }

        .Input {
            flex: 0.7 1 0;

            @include media('<=tablet') {
                flex-grow: 1;
            }

            & > input {
                border: 0.143rem solid $dark-blue;
            }
        }
    }
}