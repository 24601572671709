@import 'assets/styles/Colors.scss';

.SecondaryButton {
    background: white!important;
    color: $darkest-green!important;
    border: 0.071rem solid $darkest-green!important;

    &:hover {
        background: transparentize($light-green, 0.7)!important;
    }
}