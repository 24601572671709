// Main used colors
$darkest-green: #1D3905;
$dark-green: #2B5724;
$bright-green: #79BB3E;
$light-green: #DAECE0;
$excel-green: #2E7D32;
$dark-grey: #5E5E5E;
$pantone-432: #333F48;
$off-white: #F7F8FC;
$darkest-blue: #2E3B52;
$darker-blue: #2C5282;
$dark-blue: #606F89;
$light-blue: #DDE2FF;
$error-red: #9F3A38;
$light-error-red: #FF7A99;

// Secondary colors
$mid-dark-grey: #BBB;
$light-grey: #C3C3C3;
$lighter-grey: #EBEBEB;
$lightest-grey: #F2F2F2;
$light-red: #F2BBBB;
$lighter-red: #FFD5D5;
$positive-green: #6CB856;
$negative-red: #B02747;
$calendar-event-yellow: #FFEA00;
$calendar-event-green: #8FFFA1;
$calendar-event-day-bg: #838E8D;

// Shadow colors
$dark-shadow-grey: #111;
$light-shadow-grey: #444;
$lighter-shadow-grey: #999;

// Border colors
$light-border-grey: rgba(34, 36, 38, 0.15);