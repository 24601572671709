@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.PasswordResetPanel {
    @extend %InitialAccessPanel;

    background: transparentize(white, 0.15)!important;
    padding: 0.5rem 2rem 0.5rem 2rem!important;

    .InstructionsSentMessage {
        margin: 1.5rem 0 0 0!important;
    }

    .PasswordResetForm {
        margin: 1rem 0;

        .Header {
            color: $darkest-green;
        }
    }

    .Actions {
        margin: 0 0 1.5rem 0!important;
    }
}