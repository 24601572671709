@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.InfoMessage,
.ProfilePanel {
    margin-top: 2.5rem!important;
}

.InfoMessage {
    margin-bottom: 0!important;

    .MessageIcon {
        @include media('<=mobile') {
            display: none!important;
        }
    }

    .RoleType {
        margin-left: 0.3rem;
        font-style: italic;
    }
}

.PersonalPanels {
    display: flex;

    @include media('<=smallDesktop') {
        flex-direction: column;
    }

    .ProfilePanel,
    .ProfilePicturePanel {
        margin-top: 2.5rem!important;
    }

    .ProfilePanel {
        flex: 0.7 1 auto;
        margin-right: 1.25rem;

        @include media('<=smallDesktop') {
            flex-grow: 1;
            margin-right: 0;
        }
    }

    .ProfilePicturePanel {
        flex: 0.3 1 auto;
        margin-left: 1.25rem;

        @include media('<=smallDesktop') {
            flex-grow: 1;
            margin-left: 0;
        }
    }
}