@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.LoginPanel {
    @extend %InitialAccessPanel;

    .Header {
        @extend %InitialAccessPanelLogoWrapper;
        padding: 0.7rem 0;

        .Logo {
            margin: auto;
            width: 18rem;
        }
    }

    .MainContent {
        background: transparentize(white, 0.15)!important;
        border-radius: 0 0 0.286rem 0.286rem!important;
        padding-left: 2rem!important;
        padding-right: 2rem!important;
        padding-bottom: 0.5rem!important;

        .LoginForm {
            padding: 2rem 0 1rem;

            .ShowPasswordButton {
                background: $mid-dark-grey!important;
                color: transparentize(black, 0.2)!important;
                border-radius: 0!important;

                &:hover {
                    background: $light-grey!important;
                    color: black!important;
                }
            }
        }

        .ForgotPasswordText {
            color: $dark-grey;
            cursor: pointer;
            font-size: smaller;

            &:hover {
                color: $dark-grey;
                text-decoration: underline;
            }
        }

        .Buttons {
            width: 100%;
            margin: 1.5rem 0!important;

            .Button {
                width: 50%;
            }
        }

        .Copyright {
            text-align: center;
            color: grey;
            font-size: small;
        }
    }
}