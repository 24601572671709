@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Content {
    padding-top: 2rem;

    .ManagementLink {
        width: 70%;
        margin: 1rem auto;
        min-height: 11vw;

        @include media('<=mobile') {
            width: 100%;
        }
    }
}