@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Content {
    padding-top: 2rem;

    .CreationSteps {
        cursor: default;
        user-select: none;

        .CreationStep {
            @include media('<=mobile') {
                border-right: none;
                border-radius: inherit!important;
            }

            &[class*='active'] {
                .CreationStepTitle {
                    color: $dark-green;
                }
            }
        }
    }

    .CreationSegment {
        background: transparentize($lightest-grey, 0.6)!important;
        padding: 1.5rem 2rem;

        @include media('<=mobile') {
            padding-left: 1.5rem;
            padding-right: 1.5rem;
        }

        .MessageIcon {
            @include media('<=mobile') {
                display: none!important;
            }
        }

        .RequestedDocumentsList {
            margin-top: 0.5rem;
        }

        .SelectedFiles {
            margin-bottom: 1rem;
        }

        .FileSelector {
            @include media('>tablet') {
                padding: 2.2rem 1rem;
            }
        }

        .Actions {
            padding-top: 1rem;

            .ButtonsWrapper {
                text-align: center;
            }
        }
    }
}

.RecordNumber {
    margin: 0 0.1rem 0 0.25rem;
    padding: 0.1rem 0.2rem;
    background: $lightest-grey;
    border: 1px solid $light-border-grey;
    border-radius: 0.2rem;
}