@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Content {
    padding-top: 1.5rem;

    .UsersTable {
        @extend %StandardPanelTable;
    }
}