@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.NotificationsWidget {
    user-select: none;

    .OpenMenuButton {
        border-color: transparentize($light-grey, 0.3);
        border-style: solid;
        border-width: 0 0 0.071rem 0.071rem;
    }

    .Menu {
        @extend %HeaderWidgetMenu;

        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right-width: 0;
        box-shadow: transparentize(black, 0.75) 0 0.0625rem 0.0625rem,
                    transparentize(black, 0.75) 0 0.125rem 0.5rem,
                    transparentize(white, 0.9) 0 0 0 0.071rem inset;

        @include media('>mobile') {
            max-width: 39.286rem;
        }

        .Actions {
            display: flex;
            border-bottom: 0.143rem solid $light-grey;
            border-top-left-radius: inherit;

            .Action {
                user-select: none;
                padding: 0.6rem 1rem;
                border: 0.071rem solid transparent;
                text-align: center;
                flex-grow: 1;

                &:first-child:not(:last-child) {
                    border-right: 0.071rem solid lightgrey;
                }

                &:last-child:not(:first-child) {
                    border-left: 0.071rem solid lightgrey;
                }

                &:hover {
                    background: $lighter-grey;
                    cursor: pointer;
                }

                &.DeleteAll {
                    border-top-left-radius: inherit;
                    color: darkred;
                }

                &.ReadAll {
                    color: $dark-green;
                    flex-shrink: 0;
                }

                .ActionIcon {
                    margin-right: 0.5rem;
                }
            }
        }

        .List {
            max-height: 60vh;
            overflow-y: auto;

            .Notification {
                display: flex;
                cursor: pointer;

                &:not(.Unread) {
                    font-style: italic;

                    .ReadMarker {
                        background: $light-green;
                    }
                }

                &:nth-child(odd) {
                    background: transparentize(black, 0.95);
                }

                &:not(:last-child) {
                    border-bottom: 0.071rem solid transparentize(black, 0.9);
                }

                &:hover {
                    background: transparentize($bright-green, 0.85);
                }

                &.Unread {
                    font-weight: bold;

                    .ReadMarker {
                        background: $bright-green;
                    }
                }

                .DeletingMessage {
                    margin: auto;
                    padding: 0.7rem 0;
                    color: $dark-grey;
                    font-style: italic;
                }

                .ReadMarker {
                    width: 0.4rem;
                }

                .DeleteButton {
                    display: flex;
                    align-items: center;
                    text-align: center;
                    padding: 0 0.5rem;
                    border-width: 0 0.071rem 0 0.071rem;
                    border-style: solid;
                    border-color: transparent;

                    &:hover {
                        background: $lighter-red;
                        border-color: $light-red;
                    }

                    .DeleteIcon {
                        margin: 0;
                    }
                }

                .Content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 0.6rem 0.7rem 0.6rem 0.5rem;

                    .Timestamp {
                        font-size: smaller;
                        line-height: 1.5;
                        border-bottom: 1px dashed $light-grey;
                        color: $dark-grey;
                        margin-bottom: 0.2rem;
                    }
                }
            }
        }

        .Pagination {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0.5rem 2rem;
            border-top: 0.143rem solid $light-grey;

            .PaginationIcon {
                font-size: 1.65rem;
                line-height: 1;
                vertical-align: middle;
                color: $darkest-green;

                &:not(.Disabled):hover {
                    color: $bright-green;
                    cursor: pointer;
                }

                &.Disabled {
                    color: transparentize($darkest-green, 0.7);
                }
            }
        }
    }
}