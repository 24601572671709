@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.RecordDataPane {
    padding: 1.5rem 2rem;
    background: transparentize($lightest-grey, 0.6);

    @include media('<=mobile') {
        padding: 1.5rem 1rem;
    }

    .Actions {
        padding-top: 1rem;

        .ButtonsWrapper {
            text-align: center;
        }
    }

    .Row {
        display: flex;
        
        @include media('>mobile') {
            align-items: flex-start;
        }

        @include media('<=mobile') {
            flex-direction: column;
        }

        &:not(:last-child) {
            margin-bottom: 2rem;
        }

        &:last-child {
            margin-bottom: 0.5rem;
        }

        .DataHolder {
            flex: 1 1 0;
            padding: 0.6rem 0.8rem;
            border-style: solid;
            border-color: transparentize(black, 0.9) transparentize(black, 0.9) transparentize(black, 0.7) transparentize(black, 0.9);
            border-width: 0.071rem 0.071rem 0.143rem 0.071rem;
            border-radius: 0.3rem 0.3rem 0.2rem;
            background: transparentize($lightest-grey, 0.2);

            &:not(:last-child) {
                margin-right: 1.5rem;

                @include media('<=mobile') {
                    margin-right: 0;
                    margin-bottom: 2rem;
                }
            }

            label {
                display: block;
                font-weight: bold;
                color: $darkest-green;
                font-size: 1.1rem;
                margin-bottom: 0.4rem;
            }

            .Value {
                font-style: italic;
                font-size: 1.05rem;
                word-break: break-word;
            }
        }
    }
}