@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.LastUpdateData {
    display: flex;
    user-select: none;
        
    @include media('<=mobile') {
        flex-direction: column;
    }

    .Title {
        display: flex;
        align-items: center;
        padding: 0 1rem;
        background: $lighter-grey;
        border-radius: 0.3rem 0 0 0.3rem;
        border-width: 0.071rem;
        border-style: solid;
        border-color: transparentize($light-grey, 0.6) $light-grey transparentize($light-grey, 0.6) transparentize($light-grey, 0.6);
        font-weight: bold;
        
        @include media('<=mobile') {
            padding: 0.2rem 1rem;
            border-radius: 0.3rem 0.3rem 0 0;
            border-color: transparentize($light-grey, 0.6) transparentize($light-grey, 0.6) $light-grey transparentize($light-grey, 0.6);
        }
    }

    .Data {
        padding: 0.5rem 3rem 0.5rem 1rem;
        background: transparentize($lightest-grey, 0.5);
        border: solid transparentize($light-grey, 0.6);
        border-width: 0.071rem 0.071rem 0.071rem 0;
        border-radius: 0 0.3rem 0.3rem 0;
        
        @include media('<=mobile') {
            padding: 0.7rem 1rem;
            border-width: 0 0.071rem 0.071rem 0.071rem;
            border-radius: 0 0 0.3rem 0.3rem;
        }
    }
}