@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Calendar {
    &:hover {
        .CalendarHeader {
            color: white;
            background: $dark-green;

            .ChangeActiveDateIcon {
                color: transparentize(white, 0.1);
            }
        }

        [class='react-calendar'] {
            background: white;
        }
    }

    [class='react-calendar'] {
        background: transparentize(white, 0.3);
    }

    .CalendarHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.3rem 0.15rem 0.1rem;
        color: transparentize(white, 0.1);
        word-spacing: 0.2rem;
        background: transparentize($dark-green, 0.4);
        border-radius: 0.5rem 0.5rem 0 0;
        -webkit-transition: background 0.125s linear;
        -ms-transition: background 0.125s linear;
        transition: background 0.125s linear;

        .ChangeActiveDateIcon {
            font-size: 1.2rem;
            line-height: 1;
            vertical-align: middle;
            color: transparentize(white, 0.2);
            margin: 0;
            -webkit-transition: background 0.125s linear;
            -ms-transition: background 0.125s linear;
            transition: background 0.125s linear;

            &:hover {
                color: white;
                cursor: pointer;
            }
        }
    }

    .CreatedEventDay,
    .InvitedEventDay {
        position: relative;
        background: $calendar-event-day-bg;
        box-shadow: inset 0 0 0.2rem $darkest-blue;
        color: white;
        font-weight: bold;

        &:hover {
            color: black;
        }

        &:after {
            position: absolute;
            user-select: none;
            pointer-events: none;
            bottom: -0.3rem;
            left: 50%;
            font-size: 1.2rem;
            transform: translate(-50%, 0);
            content: '•';
        }
    }

    .CreatedEventDay {
        &:hover {
            &:after {
                color: rosybrown;
            }
        }

        &:after {
            color: $calendar-event-yellow;
        }

        &.InvitedEventDay {
            &:before {
                position: absolute;
                user-select: none;
                pointer-events: none;
                color: $calendar-event-green;
                bottom: -0.3rem;
                left: calc(50% - 0.12rem);
                font-size: 1.2rem;
                transform: translate(calc(-50% - 0.12rem), 0);
                content: '•';
            }

            &:hover {
                &:before {
                    color: darkgreen;
                }
            }

            &:after {
                left: calc(50% + 0.12rem);
                transform: translate(calc(-50% + 0.12rem), 0);
            }
        }
    }

    .InvitedEventDay {
        &:not(.CreatedEventDay) {
            &:hover {
                &:after {
                    color: darkgreen;
                }
            }

            &:after {
                color: $calendar-event-green;
            }
        }
    }
}