@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.RecordForm {
    .InvoiceField {
        @include media('<=mobile') {
            margin-bottom: 1rem!important;
        }
    }

    .ClaimedAmountField {
        > label {
            display: inline-block!important;
        }

        > .InfoIcon {
            margin-left: 0.5rem;
        }
    }

    .Checkbox {
        padding-top: 2rem;

        @include media('<=mobile') {
            padding-top: 0;
        }
    }

    .RecordStatus {
        padding-top: 1.5rem;
    }
}

.ClaimedAmountInfoPopup {
    left: 50%!important;
}