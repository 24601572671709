@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.RecordDescriptionPane {
    padding: 1.5rem 2rem;
    background: transparentize($lightest-grey, 0.6);

    @include media('<=mobile') {
        padding: 1.5rem 1rem;
    }

    .Actions {
        padding-top: 1rem;

        .ButtonsWrapper {
            text-align: center;
        }
    }
}