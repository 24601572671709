@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.RecordDocumentsPane {
    padding: 1.5rem 2rem;
    background: transparentize($lightest-grey, 0.6);

    @include media('<=mobile') {
        padding: 1.5rem 1rem;
    }

    .LoadingMessage {
        display: flex;
        align-items: center;
        justify-content: center;

        & > span {
            margin-left: 1rem;
        }
    }

    .MessageIcon {
        @include media('<=mobile') {
            display: none!important;
        }
    }

    .RequestedDocumentsList {
        margin-top: 0.5rem;

        &.SmallList {
            font-size: 0.95rem;
        }
    }

    .FileList {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -0.5rem;

        @include media('<=mobile') {
            flex-direction: column;
        }

        .FileItem {
            flex: 1 1 0;
            min-width: 22rem;
            max-width: 30rem;
            padding: 0.7rem 0.8rem;
            box-shadow: rgba(0, 0, 0, 0.25) 0 0.0625rem 0.0625rem,
                        rgba(0, 0, 0, 0.25) 0 0.125rem 0.5rem,
                        rgba(255, 255, 255, 0.1) 0 0 0 0.071rem inset;
            border-radius: 0.3rem;
            margin: 0.5rem;
            background: white;
            user-select: none;
            transition: box-shadow 0.2s;

            @include media('<=mobile') {
                max-width: unset;
                margin: 0.4rem 0;
            }

            &:hover {
                cursor: pointer;
                box-shadow: rgba(0, 0, 0, 0.4) 0 0.143rem 0.289rem,
                            rgba(0, 0, 0, 0.3) 0 0.5rem 0.929rem -0.214rem,
                            rgba(0, 0, 0, 0.2) 0 -0.214rem 0 inset;
            }

            .FileItemHeader {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;

                .FileData {
                    .FileName {
                        color: $darkest-green;
                        margin-bottom: 0;
                        word-break: break-all;
                    }

                    .FileDate {
                        color: $dark-grey;
                        margin-top: 0.2rem;
                    }
                }

                .FileIcon,
                .LoadingIcon {
                    text-align: right;
                    margin: 0 0 0 1rem;
                    font-size: 2.6rem;
                    line-height: 1;
                    vertical-align: middle;
                }
            }
        }
    }

    .Actions {
        padding-top: 1rem;

        .ButtonsWrapper {
            text-align: center;
        }
    }
}