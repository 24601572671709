@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.TableFooterPagination {
    &,
    tr,
    th {
        background: transparent!important;
        margin: 0!important;
        border: none!important;
    }

    tr,
    th {
        padding: 0!important;
    }

    th:last-child {
        text-align: right!important;

        @include media('<=mobile') {
            text-align: left!important;
        }
    }

    .PageSizeDropdown {
        margin-left: 1.5rem;
    }

    .ResultsNumberInfo {
        word-spacing: 0.214rem;
    }

    .SwitchPageButtons {
        .SwitchPageButton {
            color: $darker-blue!important;
            background: $off-white!important;
            border: 1px solid $darker-blue!important;
        }
    }
}