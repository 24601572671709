@import 'assets/styles/Colors.scss';

.SideBarLink {
    display: flex;
    align-items: center;
    border-top: 0.071rem solid transparent;
    border-bottom: 0.071rem solid transparent;
    padding: 0.8rem 1.2rem;
    color: transparentize(white, 0.1);

    &:hover {
        background: transparentize(white, 0.92);
    }

    &:hover,
    &.Active {
        color: white;
    }

    &.Active {
        border-top: 0.071rem solid transparentize(white, 0.92);
        border-bottom: 0.071rem solid transparentize(white, 0.92);
        background: transparentize(white, 0.85);
    }

    & > .Icon {
        margin-right: 0.7rem;
    }

    & > .Name {
        font-size: 1.1rem;
    }
}