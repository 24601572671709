@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.RecordCommunicationsPane {
    padding-bottom: 0.5rem;
    background-color: $pantone-432;
    border: solid black;
    border-width: 0 0.071rem 0.071rem 0.071rem;

    .LoadingMessage {
        display: flex;
        align-items: center;
        justify-content: center;

        & > span {
            margin-left: 1rem;
            color: white;
        }
    }

    .MessageIcon {
        @include media('<=mobile') {
            display: none!important;
        }
    }

    .NoMessages {
        color: transparentize(white, 0.5);
        font-size: 1.8rem;
        font-style: italic;
        font-weight: bold;
        padding: 3rem 0 4rem;
        text-align: center;
    }

    .MessageList {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        border-radius: 0.2rem;
        min-height: 25.714rem;
        max-height: calc(100vh - 29rem);
        padding: 0 1rem;
        overflow-y: auto;

        @include media('<=mobile') {
            min-height: 30rem;
        }

        .DateLabel {
            margin-bottom: 0.85rem;
            text-align: center;
            background: transparentize(black, 0.9);
            border-radius: 0.5rem;

            &:first-child {
                margin-top: 1rem;
            }

            &:not(:first-child) {
                margin-top: 2.5rem;
            }

            & > span {
                display: inline-block;
                background: transparentize(black, 0.8);
                padding: 0.4rem 1.2rem;
                color: $light-green;
                font-weight: bold;
                font-style: italic;
                border-radius: 0.5rem;
            }
        }
    }

    .InteractionArea {
        padding: 0 1rem;

        .FileInput {
            display: none;
        }

        .Errors {
            color: $light-error-red;
            font-weight: bold;
            font-style: italic;

            &.Visible {
                margin: 0.5rem 0 1rem;
            }

            & > span {
                font-size: 1.05rem;
            }
        }

        .Actions {
            display: flex;
            justify-content: flex-end;
            margin-top: 0.5rem;

            @include media('<=mobile') {
                flex-direction: column-reverse;
            }

            .ActionButton {
                border-radius: 0;
                box-shadow: 0 0 0 0.071rem $pantone-432 inset;
                color: $pantone-432;

                @include media('<=mobile') {
                    margin-right: 0;

                    &:not(:first-child) {
                        margin-bottom: 0.3rem;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}