@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.FileSelector {
    display: flex;
    background: $lightest-grey;
    flex-direction: column;
    align-items: center;
    border: 0.071rem dashed grey;
    padding: 1.5rem 1rem;
    color: grey;
    user-select: none;

    * {
        pointer-events: none;
    }

    &.DraggedOver,
    &:hover {
        background: $lighter-grey;
        color: $dark-grey;
    }

    &:hover {
        cursor: pointer;
    }

    & > div {
        display: flex;
        align-items: center;
        margin-bottom: 0.6rem;

        span {
            font-size: 2rem;
            text-align: center;
        }

        .UploadIcon {
            display: flex;
            align-items: center;
            margin-left: 1.3rem;
            font-size: 1.8rem;

            @include media('<=mobile') {
                display: none;
            }
        }
    }

    & > span {
        text-align: center;
    }

    & > input[type=file] {
        display: none;
    }
}