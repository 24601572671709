@import 'assets/styles/Colors.scss';

.HeaderBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    pointer-events: none;
    top: 0;
    z-index: 1;

    .SideBarButton {
        background: $darkest-green;
        border-radius: 0 50% 50% 0;
        pointer-events: auto;

        &:hover {
            background: $dark-green;
        }
    }

    .Widgets {
        display: flex;
        align-items: center;
        pointer-events: auto;

        .NotificationsWidget {
            margin-right: 1.3rem;
        }
    }
}