@import 'assets/styles/Colors.scss';

.SecondaryActionButton {
    color: darkred;
    user-select: none;
    padding: 0.4rem 1rem;
    border: 0.071rem solid transparent;

    &:hover {
        background: $lighter-grey;
        border: 0.071rem solid lightgrey;
        border-radius: 0.357rem;
        cursor: pointer;
    }

    .Icon {
        margin-right: 0.5rem;
    }
}

.cb-user-management-modal .cb-deregister-user .icon {
    margin-right: 0.5em;
}