@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.SearchPanel {
    .PanelContent {
        padding: 1.8rem 3.75rem;

        @include media('<=mobile') {
            padding-left: 1.3rem;
            padding-right: 1.3rem;
        }

        .PreviewCards {
            margin: 0;

            .PreviewCard {
                user-select: none;
                min-width: 12.143rem;

                &:hover {
                    background: $bright-green;

                    .PreviewTitle,
                    .PreviewContent {
                        color: $light-blue;
                    }
                }

                .PreviewTitle {
                    color: transparentize(black, 0.32);
                }
            }
        }

        .NoItemsMessage {
            @include media('<=tablet') {
                width: 100%!important;
            }

            .SearchString {
                margin-left: 0.3rem;
            }
        }
    }
}