@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.ProfilePanel,
.PermissionsPanel,
.ActionsPanel {
    margin-top: 2.5rem;
}

.PersonalPanels {
    display: flex;

    @include media('<=smallDesktop') {
        flex-direction: column;
    }

    .ProfilePanel,
    .ProfilePicturePanel {
        margin-top: 2.5rem!important;
    }

    .ProfilePanel {
        flex: 0.7 1 auto;
        margin-right: 1.25rem;

        @include media('<=smallDesktop') {
            flex-grow: 1;
            margin-right: 0;
        }
    }

    .ProfilePicturePanel {
        flex: 0.3 1 auto;
        margin-left: 1.25rem;

        @include media('<=smallDesktop') {
            flex-grow: 1;
            margin-left: 0;
        }
    }
}

.AdministrationPanels {
    display: flex;
    align-items: flex-start;

    @include media('<=smallDesktop') {
        display: block;
    }

    .PermissionsPanel,
    .ActionsPanel {
        width: 50%;

        @include media('<=smallDesktop') {
            width: 100%;
        }
    }

    .PermissionsPanel {
        margin-right: 1.25rem;

        @include media('<=smallDesktop') {
            margin-right: 0;
        }
    }

    .ActionsPanel {
        margin-left: 1.25rem;

        @include media('<=smallDesktop') {
            margin-left: 0;
        }
    }
}