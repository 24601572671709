@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.AccessRequestPanel {
    @extend %InitialAccessPanel;

    .Header {
        @extend %InitialAccessPanelLogoWrapper;
        padding: 0.7rem 0;

        .Logo {
            margin: auto;
            width: 18rem;
        }
    }

    .MainContent {
        background: transparentize(white, 0.15)!important;
        border-radius: 0 0 0.286rem 0.286rem!important;
        padding: 2rem 2rem 0.5rem 2rem;

        .AccessRequestForm {
            .Title {
                color: $darkest-green;
            }
        }

        .Actions {
            margin: 0 0 1.5rem 0!important;
        }
    }
}