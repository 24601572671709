@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.LoggedPage {
    width: 100%;
    height: 100%;
    display: flex;
    overflow-y: hidden;

    .SideBar {
        &.Hidden {
            display: none;
        }
    }

    .MainContent {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        position: relative;

        .PageContent {
            padding: 0.5rem 5rem 3.571rem 5rem;

            @include media('<=mobile') {
                padding-left: 1.5rem;
                padding-right: 1.5rem;
            }
        }
    }
}