@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.PasswordChangePanel {
    @extend %StandardPanel;

    .PanelHeader {
        font-size: 1.3rem!important;
        color: $darkest-green!important;
    }

    .PasswordChangeForm {
        margin-top: 1.5rem;

        input {
            border: 0.071rem solid transparentize($dark-green, 0.5)!important;

            &:active,
            &:focus {
                border-color: $dark-green!important;
            }
        }

        .Actions {
            text-align: right;
            margin-top: 1.5rem;

            @include media('<=mobile') {
                margin-top: 0.7rem;

                .ActionButton {
                    width: 100%;
                }
            }
        }

        .ShowPasswordButton {
            background: $mid-dark-grey!important;
            color: transparentize(black, 0.2)!important;
            border-radius: 0!important;

            &:hover {
                background: $light-grey!important;
                color: black!important;
            }
        }
    }
}
