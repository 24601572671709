@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.CalendarEventForm {
    .SeparatedField {
        @include media('<=mobile') {
            margin-bottom: 1rem!important;
        }
    }

    .MarginlessField {
        margin: 0!important;
    }

    .DisabledDropdown {
        pointer-events: none;

        i[class='dropdown icon'],
        i[class='delete icon'] {
            display: none;
        }
    }

    div[class*='field'] [readonly] {
        cursor: default;
    }

    .Reminder {
        display: flex;
        align-items: center;
        padding-bottom: 0.5rem;

        .ReminderField {
            flex-grow: 1;
            margin: 0
        }

        .DeleteReminderIcon {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-grow: 0;
            margin-left: 1rem;
            color: $negative-red;
        }
    }
}