@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.PersonalInfoPanel {
    @extend %StandardPanel;

    .PanelHeader {
        font-size: 1.3rem!important;
        color: $darkest-green!important;
    }

    .PersonalInfoForm {
        margin-top: 1.5rem;

        input {
            border: 0.071rem solid transparentize($dark-green, 0.5)!important;
            font-weight: bold;

            &:active,
            &:focus {
                border-color: $dark-green!important;
            }
        }

        .ProfilePicture {
            padding: 1rem 0;
        }

        .Actions {
            text-align: right;
            margin-top: 1.5rem;

            @include media('<=mobile') {
                margin-top: 0.7rem;

                .ActionButton {
                    width: 100%;
                }
            }
        }
    }
}