@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.Content {
    padding-top: 2rem;

    .MainPanel {
        @extend %StandardPanel;

        padding: 0;

        .OptionsBar,
        .FilterMenu {
            padding: 1.3rem 3.75rem;
            border-bottom: 0.071rem solid transparentize($dark-grey, 0.75);

            @include media('<=mobile') {
                padding-left: 1.3rem;
                padding-right: 1.3rem;
            }
        }

        .OptionsBar {
            display: flex;

            .CreateButton {
                margin-right: 0.5rem;
            }
        }

        .FilterMenu {
            background: $lighter-grey;
            box-shadow: inset 0 0.6rem 0.5rem -0.6rem $mid-dark-grey,
                        inset 0 -0.6rem 0.5rem -0.6rem $mid-dark-grey; 

            .MenuTitle {
                font-size: 1.2rem;
            }

            .FilterButtons {
                padding-top: 1rem;
                text-align: center;
            }
        }

        .DataWrapper {
            border-radius: inherit;
            
            &.NoData {
                padding: 1.8rem 3.75rem;

                @include media('<=mobile') {
                    padding-left: 1.3rem;
                    padding-right: 1.3rem;
                }
            }

            .RecordsTable {
                @extend %StandardPanelTable;

                tbody {
                    border-bottom-left-radius: unset;
                    border-bottom-right-radius: unset;
                }

                @include media('>mobile', '<=smallDesktop') {
                    th, td {
                        &:last-child,
                        &:nth-child(4),
                        &:nth-child(5) {
                            display: none;
                        }
                    }
                }
            }

            .PaginationManagement {
                padding: 1.8rem 3.75rem 0.75rem;
                border-top: 0.071rem solid transparentize($dark-grey, 0.75);

                @include media('<=mobile') {
                    padding: 0.75rem 1.3rem;
                }
            }

            .NoItemsMessage {
                @include media('<=tablet') {
                    width: 100%!important;
                }
            }
        }
    }
}