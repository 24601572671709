@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

textarea {
    min-height: 3.571rem;
}

.ui.form .field {
    min-width: 0;
}

.ui.form .field > label {
    color: $darkest-green!important;
}

.ui.form .field.error > label {
    color: $error-red!important;
}

@include media('<=mobile') {
    .ui.table:not(.unstackable) tfoot tr {
        box-shadow: none!important;
    }
}