@import 'assets/styles/Colors.scss';

.react-calendar {
    width: 350px;
    max-width: 100%;
    background: white;
    line-height: 1rem;
    border-radius: 0 0 0.5rem 0.5rem;
    border: 3px solid $dark-green;
    -webkit-transition: background 0.1s linear;
    -ms-transition: background 0.1s linear;
    transition: background 0.1s linear;

    &--doubleView {
        width: 700px;

        .react-calendar__viewContainer {
            display: flex;
            margin: -0.5rem;

            > * {
                width: 50%;
                margin: 0.5rem;
            }
        }
    }

    &,
    & *,
    & *:before,
    & *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    button {
        margin: 0;
        border: 0;
        outline: none;

        &:enabled {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__navigation {
        display: flex;
        height: 44px;
        margin-bottom: 1rem;

        button {
            min-width: 44px;
            background: none;

            &:disabled {
                background-color: rgb(240, 240, 240);
            }

            &:enabled {
                &:hover {
                    background-color: rgb(230, 230, 230);
                }
            }
        }
    }

    &__month-view {
        &__weekdays {
            text-align: center;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.65rem;

            &__weekday {
                padding: 0.3rem;
            }
        }

        &__weekNumbers {
            .react-calendar__tile {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75rem;
                font-weight: bold;
            }
        }

        &__days {
            font-size: 0.9rem;

            &__day {
                &--weekend {
                    color: rgb(209, 0, 0);
                }

                &--neighboringMonth {
                    color: rgba(117, 117, 117, 0.5);
                }
            }
        }
    }

    &__year-view,
    &__decade-view,
    &__century-view {
        .react-calendar__tile {
            padding: 2em 0.5rem;
        }
    }

    &__tile {
        max-width: 100%;
        padding: 6px 6.6667px;
        background: none;
        text-align: center;
        line-height: 16px;
        border-radius: 0.2rem;

        &:disabled {
            background-color: rgb(240, 240, 240);
        }

        &:enabled {
            &:hover {
                background-color: rgb(230, 230, 230);
            }
        }

        &--now {
            $bgcolor: lighten($bright-green, 30%);
            background: $bgcolor;
            font-weight: bold;

            &:enabled {
                &:hover {
                    background: lighten($bgcolor, 10%);
                }
            }
        }

        &--hasActive {
            $bgcolor: lighten(rgb(0, 110, 220), 30%);
            background: $bgcolor;

            &:enabled {
                &:hover {
                    background: lighten($bgcolor, 10%);
                }
            }
        }

        &--active {
            $bgcolor: rgb(0, 110, 220);
            background: $bgcolor;
            color: white;

            &:enabled {
                &:hover {
                    background: lighten($bgcolor, 10%);
                }
            }
        }
    }

    &--selectRange {
        .react-calendar__tile {
            &--hover {
                background-color: rgb(230, 230, 230);
            }
        }
    }
}
