@import 'include-media/dist/_include-media';
@import 'assets/styles/Colors.scss';
@import 'assets/styles/Constants.scss';

.UserPermissionsPanel {
    @extend %StandardPanel;

    .PanelHeader {
        font-size: 1.3rem!important;
        color: $darkest-green!important;
    }

    .UserPermissionsForm {
        margin-top: 1.5rem;

        .IsChiefCheckbox {
            margin-left: 2rem;
        }

        .Actions {
            text-align: right;
            margin-top: 1.5rem;

            @include media('<=mobile') {
                .ActionButton {
                    width: 100%;
                }
            }
        }
    }
}