@import 'assets/styles/Colors.scss';

.RoundedActionButton {
    display: inline;
    background: transparent;
    margin-bottom: 0;
    box-shadow: none;
    border-radius: 2rem;
    text-transform: uppercase;
    text-align: center;
    padding: 0.3rem 1rem;
    user-select: none;
    flex: 1 1 0;

    &:hover {
        cursor: pointer;
    }

    &.Positive {
        color: $positive-green;
        border: 0.071rem solid $positive-green;

        &:hover {
            color: white;
            background: $darkest-green;
            border-color: $darkest-green;
        }
    }

    &.Negative {
        color: $negative-red;
        border: 0.071rem solid $negative-red;

        &:hover {
            color: white;
            background: $negative-red;
            border-color: $negative-red;
        }
    }
}