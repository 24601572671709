@import 'assets/styles/Colors.scss';

.PrimaryButton {
    background: $darkest-green!important;
    color: white!important;
    border: 0.071rem solid $darkest-green!important;

    &:hover {
        background: transparentize($light-green, 0.7)!important;
        color: $darkest-green!important;
    }
}