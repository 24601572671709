@import 'include-media/dist/_include-media';
@import './Colors.scss';

$screen-mobile: 767px;
$screen-tablet: 991px;
$screen-small-monitor: 1199px;

$breakpoints: (
    'mobile': $screen-mobile,
    'tablet': $screen-tablet,
    'smallDesktop': $screen-small-monitor
);

%InitialAccessPanel {
    box-shadow: 0 0 0.714rem $dark-shadow-grey!important;
    background: transparent!important;
    border: none!important;
    padding: 0!important;
    min-width: 25rem;
    max-width: 32.143rem;
    width: 32.143rem;
}

%InitialAccessPanelLogoWrapper {
    position: relative;
    background: $pantone-432;
    box-shadow: rgba(0, 0, 0, 0.19) 0 0.714rem 1.429rem, rgba(0, 0, 0, 0.23) 0 0.429rem 0.429rem;
    border-radius: 0.286rem 0.286rem 0 0!important;
}

%StandardPanel {
    background: $light-green;
    border: 0.071rem solid $light-border-grey;
    border-radius: 0.286rem;
    padding: 1rem 2rem;
}

%HeaderWidgetMenu {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    min-width: 15rem;
    border-radius: 0.357rem;
    border: 0.071rem solid $light-grey;
    background: white;
    box-shadow: 0 0.357rem 0.714rem -0.429rem $light-shadow-grey;
}

%StandardPanelTable {
    border-bottom-left-radius: inherit;
    border-bottom-right-radius: inherit;

    thead th,
    tbody td:not(.NoResultsCell) {
        &:first-child {
            padding-left: 3.75rem;

            @include media('<=mobile') {
                padding-left: 1.3rem;
            }
        }

        &:last-child {
            padding-right: 3.75rem;

            @include media('<=mobile') {
                padding-right: 1.3rem;
            }
        }
    }

    tbody {
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;

        tr {
            &:last-child {
                border-bottom-left-radius: inherit;
                border-bottom-right-radius: inherit;

                td:first-child {
                    border-bottom-left-radius: inherit;
                }

                td:last-child {
                    border-bottom-right-radius: inherit;
                }
            }
        }
    }
}